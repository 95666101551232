// Fix für Chrome der das letzte Menü zu tief darstellt
.top-bar-section {
  ul {
    width: 100%;
  }
}

header {
  background-color: $primary-color;
  background-image: url("/images/background/ornament-150x150.png");
  text-align: center;
  nav.top-bar {
    text-align:left;
  }
}