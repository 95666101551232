.container {
  padding-bottom: 1em;
}

.row {
  background-color: $content-bg-color;
}

.columns {
  section {
    h3 {
      margin-bottom: 0.75em;
    }
  }


}

.small-12 {
  margin-top: 1.2em;
}

.large-6 {
  margin-top: 1.2em;
}

.accordion {
  .content {
    margin: 0;
    padding: 0;
  }
}


