@charset "UTF-8";
@import "fonts";
@import "settings";
@import "~foundation-sites/scss/foundation";
@import "colums";
@import "footer";
@import "header";
@import "imageCaption";

body {
  background-color: $primary-color;
  background-image: url("/images/background/ornament-150x150.png");
}